.img-round-corner5px{
  border-radius: 5px;
}
.main-header {
  height: 60px;

  position: fixed;
  width: 100%;
}

.forced-width {
  width: 48px !important;
}

.main-header.navbar.navbar-expand-md.navbar-light.navbar-white {
  transition: none !important;
}